



























































































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useUser from "@/use/user";
import useValidate from "@/use/validate";
import Joi from "joi";

export interface Company {
  id: string;
  name: string;
}

export interface Role {
  text: string | number | object;
  value: string | number | object;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  setup(_, { root }) {
    const { getRoleName, hasAccessTo, hasManyCompanies } = useUser({ root });

    const form = ref<any>(null);

    const { tlds, validate } = useValidate();

    const state = reactive({
      error: false as boolean | string,
      valid: true,
      isLoading: false,
      complete: false,
      showPassword: false,
      success: false,
      roles: computed(() => {
        const roles = [];

        roles.push({ value: "driver", text: "Kierowca" });
        roles.push({ value: "customer", text: "Klient" });
        if (hasAccessTo.value("admin"))
          roles.push({ value: "partner", text: "Partner", disabled: true });
        if (hasAccessTo.value("admin"))
          roles.push({ value: "employee", text: "Pracownik" });
        if (hasAccessTo.value("admin"))
          roles.push({ value: "admin", text: "Administrator" });

        return roles;
      }),
    });

    const model = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",

      showCompany: false,
      companies: [],

      showPassword: 0,
      password: "",
      passwordRepeat: "",
    });

    watch(
      () => model.companies,
      (companies) => {
        if (!Array.isArray(companies)) model.companies = [companies];
      },
      { deep: true, immediate: true }
    );

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      firstName: [(v) => !!v || "Podaj imię"],
      lastName: [(v) => !!v || "Podaj nazwisko"],
      email: [
        (v) => !!v || "Podaj email",
        (v) =>
          !!validate(
            v,
            Joi.string().email({
              tlds: { allow: tlds.map((d) => d.toLowerCase()) },
            })
          ) || "Podaj prawidłowy adres email",
      ],
      role: [(v) => !!v || "Wybierz rolę"],
      companies: [(v) => !!v || "Wybierz firmę"],
      password: [
        (v) => !!v || "Podaj hasło",
        (v) => v.length >= 8 || "Minimum 8 znaków",
      ],
      passwordRepeat: [
        (v) => !!v || "Powtórz hasło",
        (v) => v === model.password || "Hasło musi być takie samo",
      ],
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.valid) {
        state.showPassword = false;
        state.complete = true;
      }
    };

    const goBack = () => {
      state.showPassword = false;
      state.error = false;
      state.complete = false;
    };

    const addUser = async () => {
      await form.value.validate();
      if (state.valid) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;

        state.isLoading = true;

        axiosInstance
          .post("user", {
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            phone: model.phone,
            role: model.role,
            companies:
              model.showCompany && model.companies
                ? model.companies.map(({ id }) => id)
                : undefined,
            password:
              model.showPassword && model.password ? model.password : undefined,
            isActive: model.showPassword > 0,
          })
          .then(() => (state.success = true))
          .catch((error) => {
            if (error.response.status === 409) {
              state.error = "Użytkownik o podanym adresie email już istnieje.";
            } else if (error.response.status === 406) {
              state.error =
                "Nie udalo się wysłać wiadomości email do uzytkownika. Upewnij się, że podałeś prawdziwy adres email. W razie dalszych problemów, prosimy o kontakt z pomocą techniczną.";
            } else {
              state.error =
                "Nie udało się dodać użytkownika. Skontaktuj się z pomocą techniczną.";
            }
          })
          .finally(() => (state.isLoading = false));
      } else {
        alert("Wprowadź prawidłowe dane");
      }
    };

    const reset = () => {
      model.firstName = "";
      model.lastName = "";
      model.email = "";
      model.password = "";
      model.passwordRepeat = "";
      model.role = "";
      model.companies = [];

      state.error = false;
      state.valid = true;
      state.isLoading = false;
      state.complete = false;
      state.showPassword = false;
      state.success = false;
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      form,
      state,
      model,
      rules,
      onSubmit,
      goBack,
      getRoleName,
      addUser,
      reset,
    };
  },
});
